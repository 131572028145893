<template>
  <b-modal
    v-model="showModal"
    title="Import Definitions"
    @hidden="$emit('modal-closed')"
    @ok="onSubmit"
  >
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        label="Definitions JSON File"
      >
        <b-form-file
          v-model="definitionsFile"
          accept=".json"
          :disabled="loadingFile"
          @input="loadFile"
        />
      </b-form-group>

      <div
        v-if="loadingFile"
        class="text-center"
      >
        <b-spinner
          variant="primary"
          label="Spinner"
        />
      </div>

      <b-alert
        variant="danger"
        :show="fileLoadError !== null ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ fileLoadError }}
          </p>
        </div>
      </b-alert>

      <div v-if="fileLoaded">
        <definitions-list
          v-if="newDefinitions.length > 0"
          :definitions="newDefinitions"
          mode="new"
        />
        <definitions-list
          v-if="existingDefinitions.length > 0"
          :definitions="existingDefinitions"
          mode="existing"
        />

        <div v-if="existingDefinitions.length > 0">
          <p class="mb-25">
            Update Settings
          </p>

          <div
            v-for="(updateSetting,updateSettingIndex) of updateSettings"
            :key="updateSettingIndex"
            class="mb-1"
          >
            <div>
              {{ updateSetting.version.toUpperCase() }}
            </div>
            <div>
              <b-form-checkbox
                v-model="updateSettings[updateSettingIndex].key"
              >
                Update Key Settings
              </b-form-checkbox>
              <b-form-checkbox
                v-model="updateSettings[updateSettingIndex].table"
              >
                Update Table Settings
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="primary"
        type="submit"
        :disabled="submitting || !enableSubmit"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BButton, BForm, BSpinner, BAlert, BModal, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DefinitionsList from './DefinitionsList.vue'

export default {
  components: {
    BFormGroup,
    BButton,
    BForm,
    BSpinner,
    BAlert,
    BModal,
    BFormCheckbox,
    BFormFile,
    DefinitionsList,
  },
  data() {
    return {
      definitionsFile: null,
      definitions: [],
      updateSettings: [],
      loadingFile: false,
      fileLoaded: false,
      fileLoadError: null,
      submitting: false,
      showModal: true,
    }
  },
  computed: {
    definitionVersions() {
      return this.$store.getters['applicationSettings/definitionVersions']
    },
    newDefinitions() {
      return this.definitions.filter(definition => !definition.exists)
    },
    existingDefinitions() {
      return this.definitions.filter(definition => definition.exists)
    },
    enableSubmit() {
      return this.newDefinitions.length > 0 || this.existingDefinitions.length > 0
    },
  },
  watch: {
    existingDefinitions() {
      if (this.existingDefinitions.length === 0) {
        this.resetUpdateSettings()
      }
    },
  },
  created() {
    this.resetUpdateSettings()
  },
  methods: {
    resetUpdateSettings() {
      this.updateSettings = this.definitionVersions.map(definitionVersion => ({
        version: definitionVersion,
        key: false,
        table: false,
      }))
    },
    async loadFile() {
      this.fileLoaded = false

      if (!this.definitionsFile) {
        this.definitions = []
        this.fileLoadError = null
        return
      }

      this.loadingFile = true

      this.parseDefinitions()
        .then(definitions => this.fetchDefinitionsStatus(definitions))
        .then(definitions => {
          this.definitions = definitions
          this.fileLoadError = null
          this.fileLoaded = true
          this.loadingFile = false
        })
        .catch(error => {
          this.definitions = []
          this.fileLoadError = error.message
          this.loadingFile = false
        })
    },
    parseDefinitions() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => {
          const data = event.target.result
          try {
            const definitions = JSON.parse(data)
            resolve(definitions)
          } catch (error) {
            reject(error)
          }
        }
        reader.readAsText(this.definitionsFile)
      })
    },
    async fetchDefinitionsStatus(definitions) {
      const definitionDetails = definitions.map(definition => ({
        definition_id: definition.definition_id,
        type: definition.type,
      }))

      let res
      try {
        res = await axios.post('/pipeline/check_definitions_exist/', {
          definitions: definitionDetails,
        })
      } catch (error) {
        const message = error?.response?.data?.detail || 'Error fetching definitions status'
        throw new Error(message)
      }

      const existingDefinitions = res.data.exist
      const newDefinitions = definitions.map(definition => {
        const matchingDefinition = existingDefinitions.find(existingDefinition => existingDefinition.definition_id === definition.definition_id && existingDefinition.type === definition.type)
        return {
          exists: !!matchingDefinition,
          ...definition,
        }
      })

      return newDefinitions
    },
    onSubmit(event) {
      event.preventDefault()
      this.submitting = true

      const definitions = this.definitions.map(definition => {
        const newDefinition = {
          ...definition,
        }
        delete newDefinition.exists
        return newDefinition
      })

      axios.post('/pipeline/import_definitions/', {
        definitions,
        update_settings: this.updateSettings,
      })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.detail,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.submitting = false
          this.$emit('imported')
          this.showModal = null
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Error importing definitions',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submitting = false
        })
    },
  },
}
</script>
