<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <p>
          <feather-icon
            v-if="!expanded"
            icon="ChevronRightIcon"
            class="cursor-pointer"
            size="20"
            @click="expanded = true"
          />

          <feather-icon
            v-if="expanded"
            icon="ChevronDownIcon"
            class="cursor-pointer"
            size="20"
            @click="expanded = false"
          />

          {{ definitions.length }} Definition(s) will be {{ mode === 'new' ? 'created' : 'updated' }}
        </p>
      </div>
    </b-alert>

    <ul
      v-if="expanded"
      class="item-list"
    >
      <li
        v-for="(definition, definitionIndex) of definitions"
        :key="definitionIndex"
      >
        {{ definition.definition_id }} - {{ definition.type }}
      </li>
    </ul>
  </div>
</template>

<script>
import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    definitions: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style scoped>
.item-list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
