<template>
  <div>
    <h2>Definitions</h2>
    <definitions />
  </div>
</template>

<script>
import Definitions from '@/components/Admin/Definitions/Definitions.vue'

export default {
  components: {
    Definitions,
  },
}
</script>
